<template>
  <div class="sctp-bg-white">
    <el-alert
      title="根据监管要求，商家入驻需要完成实名认证，请完成账号负责人实名认证和企业实名认证"
      type="info"
      effect="dark">
    </el-alert>
    <div class="sctp-pad15" v-loading="!isInit">
      <el-tabs active-name="second" type="card">
        <el-tab-pane label="负责人实名认证" name="first">
          <el-form
            ref="userForm" label-width="120px" class="form"
            :disabled="!canEditForm"
            :model="userConfig.form" :rules="userConfig.formRules"
          >
            <el-form-item label="实名认证" class="auto">
              <div class="flex sctp-flex-aic">
                <div class="mg-r10" style="width: 110px;">
                  <template v-if="whetherCertified === 1 ">已认证</template>
                  <template v-else>未认证</template>
                </div>
                <template v-if="whetherCertified === 0 ">
                  <el-tag size="small">待审核</el-tag>
                </template>
                <template v-if="whetherCertified === 1 ">
                  <el-tag size="small" type="success">已完成</el-tag>
                </template>
                <template v-if="whetherCertified === 2">
                  <el-tag size="small" type="danger">未通过</el-tag>
                  <template v-if="userConfig.authHistory">
                    <span class="sctp-lh-normal mg-l10 fc-normal">原因：{{
                        userConfig.authHistory.authmemo
                      }}</span>
                  </template>
                </template>
              </div>
            </el-form-item>
            <el-form-item label="真实姓名" prop="realName">
              <el-input v-model="userConfig.form.realName"></el-input>
              <div class="el-upload__tip">注：请填写账号负责人的真实姓名</div>
            </el-form-item>
            <el-form-item label="身份证号" prop="idCard">
              <el-input v-model="userConfig.form.idCard"></el-input>
            </el-form-item>
            <template v-if="userConfig.showMoreInfo">
              <el-form-item style="height: 0;padding: 0;margin-bottom: 0;"></el-form-item>
              <el-form-item class="auto" label="身份证正面" prop="positive">
                <el-upload
                  :http-request="fileUploadFunction"
                  :data="{...uploadConfig.uploadEnum.AUTHENTICATION}"
                  action=""
                  :show-file-list="false"
                  :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'positive'})"
                  :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'positive'})">
                  <el-image
                    v-if="picture.positive" :src="picture.positive"
                    style="width: 250px; height: 150px"
                    fit="cover"></el-image>
                  <el-image
                    v-else
                    style="width: 250px; height: 150px"
                    src="/images/IDCard/0.png"
                    fit="cover"></el-image>
                  <div slot="tip" class="el-upload__tip">
                    身份证正面（国徽面）尺寸:宽≥243px，高≥153px；大小上限1M；格式JPG，GIF,PNG
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item class="auto" label="身份证反面" prop="back">
                <el-upload
                  :http-request="fileUploadFunction"
                  :data="{...uploadConfig.uploadEnum.AUTHENTICATION}"
                  action=""
                  :show-file-list="false"
                  :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'back'})"
                  :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'back'})">
                  <el-image
                    v-if="picture.back" :src="picture.back"
                    style="width: 250px; height: 150px"
                    fit="cover"></el-image>
                  <el-image
                    v-else
                    src="/images/IDCard/1.png"
                    style="width: 250px; height: 150px"
                    fit="cover"></el-image>
                  <div slot="tip" class="el-upload__tip">
                    身份证反面（头像面）尺寸:宽≥243px，高≥153px；大小上限1M；格式JPG，GIF,PNG
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item v-if="whetherCertified==3||whetherCertified==2">
                <el-button @click="userConfig.onSubmitForm('userForm')" type="primary"
                           class="sctp-square">提交负责人实名认证
                </el-button>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item>
                <el-button @click="userConfig.onCheckFormClick('userForm')" type="primary"
                           class="sctp-square">
                  提交负责人实名认证
                </el-button>
              </el-form-item>
            </template>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="企业实名认证" name="second">
          <el-form
            class="form"
            ref="form" :model="businessConfig.form" :rules="businessConfig.formRules"
            label-width="120px"
          >
            <el-form-item label="企业实名认证" class="auto">

              <div class="flex sctp-flex-aic">

                <template v-if="enterprise_authflag  == 0">
                  待审核
                </template>
                <template v-if="enterprise_authflag === 1">
                  已完成
                </template>
                <template v-if="enterprise_authflag === 2">
                  未通过
                  <template v-if="userConfig.authHistory">
                    <span class="sctp-lh-normal mg-l10 fc-normal">原因：{{
                        userConfig.enterpriseAuthHistory.authmemo
                      }}</span>
                  </template>
                </template>
                <template v-if="enterprise_authflag === 3">
                  未提交
                </template>
              </div>
            </el-form-item>
              <el-form-item class="w500" label="企业名称" prop="enterprise_name">
              <el-input :disabled="!canEditForm"
                        v-model="businessConfig.form.enterprise_name"></el-input>
            </el-form-item>
            <el-form-item class="w500" label="法人姓名" prop="legalperson_name">
              <el-input :disabled="!canEditForm"
                        v-model="businessConfig.form.legalperson_name"></el-input>
            </el-form-item>
            <el-form-item label="经营范围" prop="business_scope">
              <el-input
                :disabled="!canEditForm"
                type="textarea"
                placeholder="请输入营业执照上的经营范围"
                v-model="businessConfig.form.business_scope"
                maxlength="100"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item label="营业执照" prop="business_license">
              <el-upload
                :disabled="!canEditForm"
                :http-request="fileUploadFunction"
                :data="{...uploadConfig.uploadEnum.USER}"
                action=""
                :show-file-list="false"
                :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'business_license'})"
                :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'business_license'})">
                <el-image
                  class="preview-img"
                  v-if="picture.business_license" :src="picture.business_license"
                  fit="cover"></el-image>
                <el-button v-else size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件, 最大限制1M</div>
              </el-upload>
            </el-form-item>
            <el-form-item label="行业类型">
              <el-select :disabled="false" v-model="businessConfig.form.industry_type" multiple
                         placeholder="请选择您的行业类型">
                <el-option
                  v-for="item in businessConfig.metadata.industry"
                  :label="item.label" :value="item.value" :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="企业规模">
              <el-select v-model="businessConfig.form.enterprise_size" placeholder="请选择您的企业规模">
                <template v-for="item in businessConfig.metadata.enterpriseSize">
                  <el-option :label="item.label" :value="item.value" :key="item.value"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="企业人数">
              <el-select v-model="businessConfig.form.total_staff" placeholder="请选择您的企业人数">
                <template v-for="item in businessConfig.metadata.staffSize">
                  <el-option :label="item.label" :value="item.value" :key="item.value"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item class="w500" label="公司官网" prop="company_website">
              <el-input v-model="businessConfig.form.company_website"></el-input>
            </el-form-item>
            <el-form-item label="公司简介">
              <ckeditor placeholder="介绍一下公司吧..."
                        v-model="businessConfig.form.company_profile"></ckeditor>
            </el-form-item>
            <el-form-item>
              <template
                v-if="(enterprise_authflag !== 0 && enterprise_authflag !== 1) || (account_type === 1 && enterprise_authflag === 0)">
                <el-button :loading="submitting" @click="onSubmitForm('form')" type="primary">
                  提交企业实名认证
                </el-button>
              </template>
              <template v-else>
                <el-button :loading="submitting" @click="onSaveClick('form')" type="primary">保存
                </el-button>
              </template>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import validator from '../../../../resources/js/async-validator'
import {common, user} from "@/apis/index";
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../../resources/js/config')
export default {
  name: 'verified',
  components: {
    ckeditor: () => import('@CMP/ckeditor/Index'),
  },
  filters: {},
  data() {
    return {
      isInit: false,
      submitting: false,
      userConfig: {
        form: {
          realName: null,
          idCard: null,
          positive: null,
          back: null
        },
        formRules: {
          realName: [
            {required: true, message: '真实姓名不能为空', trigger: ['blur', 'change']}
          ],
          idCard: [
            {required: true, message: '身份证不能为空', trigger: ['blur', 'change']},
            {validator: validator.validID, message: '身份证格式错误', trigger: ['blur', 'change']}
          ],
          positive: [
            {required: true, message: '请上传身份证正面照（国徽面）'},
          ],
          back: [
            {required: true, message: '请上传身份证背面照（头像面）'}
          ]
        },
        phone: null,
        authHistory: null,
        enterpriseAuthHistory: null,
        showMoreInfo: false,
        onCheckFormClick: (formName) => {
          this.$refs[formName].validate().then(res => {
            let {form, phone} = this.userConfig;
            user.checkPersonalVerified({
              userId: this.user.userId,
              username: form.realName,
              phone: phone,
              idNumber: form.idCard
            }).then(res => {
              let {certificationflag: retFlag} = res.retdata;
              if (retFlag === '0') {
                // 认证失败
                this.$confirm('实名认证失败，是否进行人工认证?', '提示', {
                  confirmButtonText: '人工认证',
                  cancelButtonText: '取消认证',
                  type: 'warning'
                }).then(() => {
                  // 进行人工认证
                  this.userConfig.showMoreInfo = true;
                });
              } else {
                // 认证成功，进行身份保存
                this.$request.post({
                  reqcode: '1055',
                  reqdesc: '提交实名认证',
                  userId: this.user.userId,
                  realName: form.realName,
                  idCard: form.idCard,
                  type: 1
                }).then(res => {
                  this.$message.success('提交成功')
                  this.getVerified();
                })
              }
            })
          });
        },
        onSubmitForm: (formName) => {
          this.$refs[formName].validate().then(res => {
            let {form} = this.userConfig;
            this.$request.post({
              reqcode: '1055',
              reqdesc: '提交实名认证',
              userId: this.user.userId,
              realName: form.realName,
              idCard: form.idCard,
              positive: form.positive.substring(form.positive.indexOf('/upload/')),
              back: form.back.substring(form.back.indexOf('/upload/')),
              type: 1
            }).then(res => {
              this.$message.success('提交成功')
              this.getVerified()
            })
          }).catch((err) => {
            console.log(err);
            this.$message.warning('请完成表单')
          })
        }
      },
      businessConfig: {
        form: {
          enterprise_name: null,
          legalperson_name: null,
          business_license: null,
          bankcard: null,
          business_scope: null,
          industry_type: null,
          enterprise_size: null,
          total_staff: null,
          company_website: null,
          company_profile: null,
        },
        formRules: {
          enterprise_name: [
            {required: true, message: '企业名称不能为空', trigger: ['blur', 'change']}
          ],
          legalperson_name: [
            {required: true, message: '法人姓名不能为空', trigger: ['blur', 'change']}
          ],
          bankcard: [
            {required: true, message: '银行卡号不能为空', trigger: ['blur', 'change']},
            {validator: validator.validBankID, message: '银行卡号不正确', trigger: ['blur', 'change']}
          ],
          business_scope: [
            {required: true, message: '经营范围不能为空', trigger: ['blur', 'change']}
          ],
          business_license: [
            {required: true, message: '请上传营业执照', trigger: ['blur', 'change']}
          ],
          company_website: [
            {
              validator: (rule, value, callback) => {
                if (value) {
                  const valid = /^((https?):\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
                  if (!valid.test(value)) {
                    callback(rule.message);
                    return;
                  }
                }
                callback();
              }, message: '请输入正确地址', trigger: ['blur', 'change']
            }
          ]
        },
        metadata: {
          editMore: false,
          staffSize: [
            {label: '0-20人', value: 1},
            {label: '20-99人', value: 2},
            {label: '100-499人', value: 3},
            {label: '500-999人', value: 4},
            {label: '1000-9999人', value: 5},
            {label: '10000人以上', value: 6}
          ],
          enterpriseSize: [
            {label: '大型企业', value: 1},
            {label: '中型企业', value: 2},
            {label: '小型企业', value: 3},
            {label: '微型企业', value: 4}
          ],
          industry: []
        },
        loadCondition: () => {
          common.loadConfigIndustry().then(res => {
            let {retdata = []} = res;

            this.businessConfig.metadata.industry = retdata.map(item => {
              return {
                label: item.industryname,
                value: item.id,
              }
            });
          })
        },
      },
      picture: {
        business_license: null,
        positive: null,
        back: null
      },
      whetherCertified: 0,
      enterprise_authflag: 0,
      account_type: 0,
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({key}, response, file, fileList) => {
          if (key === 'business_license') {
            this.businessConfig.form.business_license = response.src;
            this.picture.business_license =  response.src;
          } else if (key === 'back') {
            const {src, preview} = response
            this.userConfig.form.back = src;
            this.picture.back = preview;
            this.$message.success('上传成功');
            this.$refs['userForm'].validateField('back');
          } else if (key === 'positive') {
            const {src, preview} = response;
            this.userConfig.form.positive = src;
            this.picture.positive = preview;
            this.$message.success('上传成功');
            this.$refs['userForm'].validateField('positive');
          }
          this.$message.success('文件上传成功！');
        },
        onRemove: ({key}, file, fileList) => {
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          if (key === 'business_license') {
            const isImage = file.type.startsWith('image')
            const isLtSize = file.size / 1024 / 1024 < 1
            if (!isImage) {
              this.$message.error('上传文件只能是 图片 格式!')
            }
            if (!isLtSize) {
              this.$message.error('上传文件大小不能超过 1M!')
            }
            result = isImage && isLtSize;
          } else if (['back', 'positive'].includes(key)) {
            const isImage = file.type.startsWith('image')
            const isLtSize = file.size / 1024 / 1024 < 1
            if (!isImage) {
              this.$message.error('上传的文件只能是 图片 格式!')
            }
            if (!isLtSize) {
              this.$message.error('上传的文件大小不能超过 1M!')
            }
            result = isImage && isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      },
    }
  },
  methods: {
    getVerified() {
      return this.$request.post({
        reqcode: '1054',
        reqdesc: '实名认证状态',
        userId: this.user.userId,
        invalid_cache: new Date().getTime(),
      }).then(res => {
        const {retdata} = res;
        this.whetherCertified = retdata.auth;
        this.enterprise_authflag = retdata.enterprise_authflag
        this.account_type = retdata.account_type;
        this.businessConfig.form = {
          enterprise_name: retdata.enterprise_name || null,
          legalperson_name: retdata.legalperson_name || null,
          bankcard: retdata.bankcard || null,
          business_license: retdata.business_license || null,
          business_scope: retdata.business_scope || null,
          industry_type: retdata.industry_type ? retdata.industry_type.split(',').map(
            item => Number(item)) : null,
          enterprise_size: retdata.enterprise_size || null,
          total_staff: retdata.total_staff || null,
          company_website: retdata.company_website || null,
          company_profile: retdata.company_profile || null
        }
        this.picture = {
          business_license: retdata.business_license || null,
          positive: retdata.positive || null,
          back: retdata.back || null
        }

        this.userConfig.phone = retdata.phone
        this.userConfig.authHistory = retdata.authHistory;
        this.userConfig.enterpriseAuthHistory = retdata.enterpriseAuthHistory;
        this.userConfig.form = {
          realName: retdata.realName || null,
          idCard: retdata.idCard || null,
          positive: retdata.positive || null,
          back: retdata.back || null
        }
        if (retdata.positive || retdata.back) {
          this.userConfig.showMoreInfo = true;
        }
      })
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate().then(res => {
        this.$request.post({
          reqcode: '1055',
          reqdesc: '提交企业实名认证',
          userId: this.user.userId,
          enterprise_name: this.businessConfig.form.enterprise_name,
          legalperson_name: this.businessConfig.form.legalperson_name,
          business_license: this.businessConfig.form.business_license.substring(
            this.businessConfig.form.business_license.indexOf('/upload/')),
          bankcard: this.businessConfig.form.bankcard,
          business_scope: this.businessConfig.form.business_scope,
          industry_type: this.businessConfig.form.industry_type,
          enterprise_size: this.businessConfig.form.enterprise_size,
          total_staff: this.businessConfig.form.total_staff,
          company_website: this.businessConfig.form.company_website,
          company_profile: this.businessConfig.form.company_profile,
          type: 2,
          account_type: this.account_type
        }).then(res => {
          this.$message.success('提交成功')
          this.getVerified();
        })
      }).catch(() => {
        this.$message.warning('请完成表单')
      })
    },
    onSaveClick(formName) {
      this.$refs[formName].validate().then(() => {
        this.submitting = true;
        user.saveBusinessInformation({
          userId: this.user.userId,
          industryType: this.businessConfig.form.industry_type,
          enterpriseSize: this.businessConfig.form.enterprise_size,
          totalStaff: this.businessConfig.form.total_staff,
          companyWebsite: this.businessConfig.form.company_website,
          companyProfile: this.businessConfig.form.company_profile,
        }).then(res => {
          this.$message.success('保存成功');
        }).finally(() => {
          this.submitting = false;
        })
      })
    }
  },
  computed: {
    canEditForm() {
      let flag = true
      if (
        (this.enterprise_authflag === 0 ||
          this.enterprise_authflag === 1) &&
        this.account_type != 1
      ) {
        flag = false
      }
      return flag
    },
  },
  beforeMount() {
    this.getVerified().finally(() => {
      this.isInit = true;
    });
    this.businessConfig.loadCondition();
  }
}
</script>

<style scoped lang="scss">
.form ::v-deep .el-textarea__inner {
  min-height: 100px !important;
}

.preview-img {
  width: 50px;
  height: 90px;
  cursor: pointer;
  transform-origin: left center;
  transition: all 0.5s;
  z-index: 99;

  &:hover {
    transform: scale(6);
  }

}

.w500 {
  width: 500px;
}

.type {
  padding: 10px 0px 0px 20px;
  font-size: 14px;
  color: #606266;
}

.status {
  padding: 10px 50px 0px 0px;
  font-size: 20px;
  color: #F90;
  float: right;
}
</style>
